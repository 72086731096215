import React from 'react';
import styled from 'styled-components';
import {graphql, useStaticQuery} from "gatsby";

const ParagraphContainer = styled.div`
  width: 75%;
  z-index: 1;
  padding-top: var(--spacing-l);
  margin: 0 auto;
  font-size: 1.2rem;
  font-family: var(--font-small);
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 90%;
  }
  
  address {
    font-style: normal;
  }
  
  h3 {
    margin: var(--spacing-m) 0 var(--spacing-s) 0;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  dl {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: var(--spacing-xs);
  }
  
  dt {
    font-weight: 300;
    color: #666;
    margin-right: var(--spacing-s);
    min-width: 9rem;
  }
  
  dd {
    clear: right;
  }
  
  a {
    color: #202020;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const settingToValueMap = (settings) => {
  return settings.reduce((settingsKeyValuePair, setting) => {
    settingsKeyValuePair[setting.title] = {
      id: setting.id,
      value: setting.value
    }
    return settingsKeyValuePair;
  }, {});
}

export default function Paragraph(node) {

  const { settings } = useStaticQuery(graphql`
        query contactInformationSettings {
            settings: allSanitySettings {
              nodes {
                title
                value
                id
              }
            }
        }
  `);
  const settingsPairs = settingToValueMap(settings.nodes);
  const { paragraph } = node;

  return <ParagraphContainer>
    <p>{paragraph}</p>
    <h3>Adres</h3>
    <address>
      {settingsPairs.street.value} {settingsPairs.houseNumber.value}<br />
      {settingsPairs.zipCode.value} {settingsPairs.city.value}
    </address>
    <h3>Bedrijfsgegevens</h3>
    <dl>
      <dt>KvK nummer:</dt><dd>{settingsPairs.kvkNumber.value}</dd>
    </dl>
    <dl>
      <dt>BTW nummer:</dt><dd>{settingsPairs.vatNumber.value}</dd>
    </dl>
    <h3>Contact gegevens</h3>
    <dl>
      <dt>Telefoon:</dt>
      <dd>
        <a href={`tel:${settingsPairs.mobilePhone.value}`} title={`Bel ${settingsPairs.mobilePhone.value}`}>
          {settingsPairs.mobilePhone.value}
        </a>
      </dd>
    </dl>
    <dl>
      <dt>Email:</dt>
      <dd>
        <a href={`mailto:${settingsPairs.fromEmail.value}`} title={`Mail ${settingsPairs.fromEmail.value}`}>
          {settingsPairs.fromEmail.value}
        </a>
      </dd>
    </dl>
  </ParagraphContainer>;
}
